import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout";
const imageBaconLoversSan = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-the-adulting-iltimate-bacon-lovers-sandwich-recipe-1367x737px.jpg';

const RecipePage = () => (
    <Layout metaTitle="Ultimate Bacon Lover's Sandwich"
        metaDescription="Unleash your love for bacon with our Ultimate
        Bacon Lovers Sandwich recipe. A heavenly
        combination of crispy bacon and fresh
        ingredients in every bite!"
        metaKeywords=""
    >
        <meta property="og:image" content={imageBaconLoversSan} />
        <link rel="image_src" href={imageBaconLoversSan} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconLoversSan} class='img-responsive' alt="“The Adulting” Ultimate Bacon Lover's Sandwich" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>“The Adulting” Ultimate Bacon Lover's Sandwich</h1>

                            <div class="recipe-content">
                                <p >By: Crissy's Pub Style - Benton, AR<br></br>Featured at Bacon City USA - Food Truck Competition 2023<br></br>“This is the ultimate bacon sandwich and when you dig in, you'll realize it for yourself.” - Crissy’s Pub Style
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        1&nbsp;hour                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        45&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>1</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                4 slices of Wright® Brand, Hickory Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 slices sourdough bread (buttered & grilled)
                </div>      <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 ounces prime ground beef
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                4 ounces shredded cheddar cheese
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 slices smoked gouda cheese
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 large farm fresh eggs (cooked over easy) 
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 ounce creole mayo
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 ounce spicy brown mustard
                                 
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                green leaf lettuce
                                 
                </div>      
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 slices of Roma tomatoes
                                 
                </div> 
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 cup melted butter
                                 
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 cup light brown sugar
                                 
                </div> 
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 cup pure maple syrup
                                 
                </div> 
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 teaspoon creole seasoning
                 
                </div> 
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 ounces Bourbon of your choice
                                 
                </div>
                        </div>

                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Heat a flat top griddle to 400F.

                </p>
                            <p>
                                2. Place the cheddar cheese directly on the flat top grill, then put the sourdough toast directly into the melting cheese (allow it to crisp before removing from the flat top).
                </p>
                            <p>
                                3. Ball up prime ground beef into a circle.
                </p>
                            <p>
                                4. Pour your favorite creole seasoning into a bowl and dip your beef ball directly into the seasoning only covering 1 side. 
                </p>
                           <p>
                                5. Place the covered side directly on the griddle and smash with a press; leave the press on for approximately 1.5 minutes to get the perfect crust on your burger.
                </p>
                           <p>
                                6. Once you flip allow another 1.5 minutes under the press or until cooked to at least 145°F & add 2 slices of smoked gouda cheese while on the griddle to melt.
                </p>
                           <p>
                                7. Build your Sandwich: Place one piece of sourdough toast with the cheese side down and spread the face with spicy mustard. Place cooked burger with cheese. Top it off with your two over easy eggs. Add hickory smoked & candied Wright ® Brand bacon, lettuce & tomato. Spread creole mayo onto 
                                2nd piece of bread & and top sandwich.
                </p>
                           <p>
                                8. Candied Bacon Directions: Add brown sugar and maple syrup to melted butter allowing all sugar to dissolve before adding creole seasoning & bourbon and cooking off the alcohol. Allow mixture to cool slightly before dropping in bacon allowing even coating and then individually laying candied Wright ® Brand bacon strips to cool on wire rack or parchment paper.
                </p>     
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
